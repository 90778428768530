@keyframes logo-spin {
	0% {
		transform: rotate(0deg);
	}

	40% {
		transform: rotate(210deg);
	}

	70% {
		transform: rotate(160deg);
	}

	85% {
		transform: rotate(195deg);
	}

	100% {
		transform: rotate(180deg);
	}
}

.cmp-header {
	align-items: stretch;
	border-top: get-rem(8px) solid var(--palette-primary-main);
}

.cmp-header__inner {
	display: flex;
	flex-direction: row;
}

.cmp-header__heading {
	align-items: center;
	background-color: var(--palette-app-primary-main);
	border-left: get-rem(1px) solid black;
	color: white;
	display: flex;
	font-size: get-rem(20px);
	font-weight: bold;
	padding: 0 get-rem(22px);
}

/*
	&__logo {
		background-color: set-color(application, dark);
		background-image: url(../../../../images/logo-main.svg);
		background-position: center;
		background-repeat: no-repeat;
		border-right: 0.1rem solid #434343;
		display: block;
		height: 7rem;
		width: calc(7rem + 0.1rem);

		&::after {
			background-image: url(../../../../images/logo-arrows.svg);
			background-position: center;
			background-repeat: no-repeat;
			content: "";
			display: block;
			height: 100%;
			width: 100%;
		}

		&:focus::after,
		&:hover::after {
			animation: logo-spin 1s;
		}

		span {
			@include hidden-visually;
		}
	}

	&__heading {
		align-items: center;
		background-color: set-color(application, dark);
		border-left: 0.1rem solid set-color(black);
		color: white;
		display: flex;
		font-size: 2rem;
		font-weight: bold;
		padding: 0 2.2rem;
	}*/