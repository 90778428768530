#defaultFormNarrow {
	margin: auto;
	max-width: get-rem(325px);

	/*.cmp-button {
		width: 100%;
	}*/
}

#defaultForm {
	margin: auto;
	max-width: get-rem(480px);

	/*.cmp-button {
		display: flex;
		width: 100%;
		margin: auto;
		max-width: get-rem(325px);
	}*/
}

#defaultFormWide {
	margin: auto;
	max-width: get-rem(540px);
}