@keyframes logo-spin {
	0% {
		transform: rotate(0deg);
	}

	40% {
		transform: rotate(210deg);
	}

	70% {
		transform: rotate(160deg);
	}

	85% {
		transform: rotate(195deg);
	}

	100% {
		transform: rotate(180deg);
	}
}

.cmp-logo {
	border-right: get-rem(1px) solid #434343;
	display: flex;
	justify-content: center;

	a {
		height: get-rem(70px);
		width: get-rem(70px);

		&:focus,
		&:hover {
			animation: logo-spin 1s;
		}
	}
}

.cmp-logo span {
	@include a11y-hidden;
}