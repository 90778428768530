.cmp-form-select {
	margin: 2rem 0;

	label {
		display: block;
		font-family: var(--typography-primary-font-semi-bold);
		font-weight: 600;
		margin: 0 0 0.4rem;
	}
}

.cmp-form-select__input-wrap {

	select {
		padding: 1.2rem 0.8rem;
		width: 100%;
	}
}