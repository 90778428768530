.cmp-form-text {
	display: flex;
	justify-content: center;
	flex-direction: column;
}

.cmp-form-text__input {
	border: get-rem(1px) solid var(--palette-neutral-dark);
	border-radius: get-rem(3px);
	color: black;
	flex: 1 1 auto;
	font-family: var(--typography-primary-font);
	font-size: get-rem(16px);
	margin: 0;
	padding: 0;
	width: 100%;

	.cmp-form-text--isFocused & {
		border: get-rem(2px) solid var(--palette-link-main);
	}

	.cmp-form-text--isInvalid & {
		border-color: var(--palette-error-main);
	}

	&[type="password"] {

		&::-ms-reveal,
		&::-ms-clear {
			display: none;
		}
	}
}

.cmp-form-text--animated-label {
	margin: get-rem(24px) 0;
	position: relative;

	&.cmp-form-text--isInvalid {
		margin-bottom: get-rem(4px);
	}

	label {
		background-color: transparent;
		color: var(--palette-text-primary);
		cursor: text;
		display: inline-block;
		font-family: var(--typography-primary-font-light);
		font-size: get-rem(14px);
		left: get-rem(8px);
		line-height: 1;
		order: 1;
		padding: 0 get-rem(5px);
		position: absolute;
		transition: all 0.25s ease-out;
		top: get-rem(16px);
	}

	&.cmp-form-text--isActive label {
		cursor: default;
		font-size: get-rem(12px);
		top: get-rem(6px);
	}

	.cmp-form-text__input {
		padding: get-rem(14px) get-rem(12px) get-rem(2px);
		min-height: get-rem(44px);
	}

	&.cmp-form-text--isFocused .cmp-form-text__input {
		padding: get-rem(13px) get-rem(11px) get-rem(1px);
	}
}

.cmp-form-text--no-bottom-margin {
	margin-bottom: 0 !important;
}

.cmp-form-text__supporting-context {
	color: var(--palette-text-secondary);
	font-family: var(--typography-primary-font);
	font-size: get-rem(12px);
	margin: get-rem(8px) 0 0;
	padding: 0 get-rem(12px);
}

.cmp-form-text--isInvalid {

	label {
		color: var(--palette-error-main);
	}
}

.cmp-form-text__input-validity {
	align-items: center;
	color: var(--palette-error-main);
	display: flex;
	font-family: var(--typography-primary-font);
	font-size: get-rem(12px);
	margin: get-rem(8px) 0;
	padding: get-rem(4px) get-rem(12px);

	&:empty {
		display: none;
	}

	&:before {
		background-image: url($alertIconRedBase64);
		content: "";
		display: block;
		flex: 0 0 auto;
		height: get-rem(14px);
		margin-right: get-rem(5px);
		width: get-rem(14px);
	}
}

.cmp-form-text__additional-details {
	background-color: var(--palette-neutral-main);
	margin: get-rem(14px) 0 0;
	padding: get-rem(14px);
}

.cmp-form-text__additional-details__text {

	p,
	pre,
	blockquote,
	li {
		font-size: get-rem(12px);
	}

	ul {
		list-style-type: disc;
		margin: get-rem(10px) 0;
	}

	li {
		margin: get-rem(4px) 0 get-rem(4px) get-rem(16px);

		>ul {
			margin: 0;
		}
	}

	:last-child {
		margin-bottom: 0;
	}
}

.cmp-form-text--isPasswordReveal {

	/* This will keep password from overflowing reveal button */
	.cmp-form-text__input {
		padding-right: get-rem(44px);
	}

	&.cmp-form-text--isFocused .cmp-form-text__input {
		padding-right: get-rem(43px);
	}
}

.cmp-form-text__password-reveal {
	background: none;
	border: none;
	height: get-rem(32px);
	margin: 0;
	padding: 0;
	position: absolute;
	right: get-rem(4px);
	top: get-rem(7px);
	width: get-rem(32px);

	img {
		display: block;
		min-width: 100%;
	}
}