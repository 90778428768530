.cmp-form-text-area {
	background: #F2F4F6;
	margin: 2rem 0;
	padding: 0.8rem;

	label {
		display: block;
		font-family: var(--typography-primary-font-semi-bold);
		font-weight: 600;
		margin: 0 0 0.4rem;
	}
}

.cmp-form-text-area__input-wrap {

	textarea {
		font-size: 1.2rem;
		min-height: 8rem;
		padding: 0.8rem;
		width: 100%;
	}
}

.cmp-progress-meter {
	display: flex;
	flex-direction: column;
	font-size: 1.2rem;
	align-items: flex-end;
}

.cmp-progress-meter__progress-bar {
	width: 100%;
}

.cmp-progress-meter__progress-indicator {
	background: var(--palette-primary-main);
	height: 0.2rem;
	transition: width 0.1s linear;
}

.cmp-progress-meter__message {
	@include a11y-hidden;
}

.cmp-progress-meter__description {
	margin-left: 0.8rem;
}

.cmp-form-text-area__input-validity {
	color: var(--palette-error-main);
}

.cmp-form-text-area--isInvalid {

	.cmp-form-text-area__input-wrap textarea {
		border-color: var(--palette-error-main);
	}
}