@use "sass:math";

.cmp-task-header {
	--task-close-height: #{get-rem(2px)};
	--task-close-width: #{get-rem(18px)};
	--task-close-hypot: #{math.hypot(18, 18)};
	--task-header-height: #{get-rem(64px)};
	border-bottom: get-rem(3px) solid var(--palette-primary-main);
	display: flex;
	flex-direction: column-reverse;
	margin-bottom: get-rem(24px);
	min-height: var(--task-header-height);
	padding: 0 get-rem(14px);
	position: relative;
}

.cmp-task-header__label {
	text-align: center;
}

.cmp-task-header__close {
	align-self: flex-end;
}

.cmp-task-header__close-label {
	@include a11y-hidden;
}