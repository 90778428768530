.cmp-loading--access {
	margin: 20rem auto 0;
	max-width: 72rem;
}

.cmp-loading--access__loading-icon {
	display: block;
	margin: 0 auto;
	position: relative;
	width: 8rem;
	height: 8rem;

	div {
		$loadingRingColor: var(--palette-primary-main);

		animation: loading-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
		border: 0.8rem solid $loadingRingColor;
		border-color: $loadingRingColor transparent transparent transparent;
		border-radius: 50%;
		display: block;
		height: 6.4rem;
		margin: 0.8rem;
		position: absolute;
		width: 6.4rem;

		&:nth-child(1) {
			animation-delay: -0.45s;
		}

		&:nth-child(2) {
			animation-delay: -0.3s;
		}

		&:nth-child(3) {
			animation-delay: -0.15s;
		}
	}
}

.cmp-loading--access__loading>span {
	display: block;
	margin: 2rem auto;
	text-align: center;
}

@keyframes loading-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}